<template>
  <div>
    <FiltriTesseratiUnaSocieta
      @getTesserati="searchTesseratoList"
      @resetFilters="resetFilters"
      :societa="societa"
    />
    <TableTesseratiUnaSocieta
      @getTesserati="getTesserati"
      @resetFilters="resetFilters"
      :societa="societa"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FiltriTesseratiUnaSocieta from "@/components/components-fit/società/tesserati-unasocieta/FiltriTesseratiUnaSocieta.vue";
import TableTesseratiUnaSocieta from "@/components/components-fit/società/tesserati-unasocieta/TableTesseratiUnaSocieta.vue";
import { useStore } from "vuex";
import { trimInput } from "@/composables/trimInput";
export default defineComponent({
  name: "tesserati-unasocieta",
  props: {
    societa: {
      type: Object,
      required: true,
    },
  },
  components: {
    FiltriTesseratiUnaSocieta,
    TableTesseratiUnaSocieta,
  },
  setup(props) {
    // inclusioni store e global api
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    // stagione da store
    const stagione = computed(() => store.getters.stagioneSelected);
    // filtri da store (store>modules>FiltriTesseratiSocieta.js)
    const cognome = computed(() => store.getters.cognomeTesserato);
    const codiceTessera = computed(() => store.getters.codiceTesseraTesserato);
    const inizioTesseramento = computed(
      () => store.getters.inizioTesseramentoTesserato
    );
    const fineTesseramento = computed(
      () => store.getters.fineTesseramentoTesserato
    );
    const certificato = computed(() => store.getters.certificatoTesserato);
    const id_tipo_tessera = computed(
      () => store.getters.id_tipo_tesseraTesserato
    );
    const id_categoria = computed(() => store.getters.id_categoriaTesserato);
    const id_disciplina = computed(() => store.getters.id_disciplinaTesserato);
    const id_qualifica = computed(() => store.getters.id_qualificaTesserato);
    const rowsToSkip = computed(() => store.getters.rowsToSkipTesserato);
    const fetchRows = computed(() => store.getters.fetchRowsTesserato);
    const sortColumn = computed(() => store.getters.sortColumnTesserato);
    const sortOrder = computed(() => store.getters.sortOrderTesserato);

    const getTesserati = () => {
      // attivato tramite evento @getTesserati
      // prendo lista da store dinamico. Passo keys (i parametri in input)
      // apiLink(link all'api), itemName(nome dell'array)
      store.dispatch("setStoreListData", {
        keys: {
          id_societa: props.societa.id,
          stagione: stagione.value,
          nome: null,
          cognome: trimInput(cognome.value),
          certificato: certificato.value,
          id_tipo_tessera: id_tipo_tessera.value,
          id_categoria: id_categoria.value,
          id_qualifica: id_qualifica.value,
          id_disciplina: id_disciplina.value,
          id_categoria_eta: null,
          cod_tessera: trimInput(codiceTessera.value),
          data_emissione_da: inizioTesseramento.value,
          data_emissione_a: fineTesseramento.value,
          id_comitato: null,
          id_regione: null,
          id_provincia: null,
          id_comune: null,
          tessere_estive: null,
          da_approvare: null,
          stampate: null,
          stampate_dal: null,
          stampate_al: null,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.TESSERATI_SOCIETA_LIST,
        itemName: "tesserati_list",
        version: 2,
      });
    };
    // reset filtri. viene attivato tramite evento @resetFilters
    const resetFilters = () => {
      store.commit("resetFiltersTesserato");
      store.commit("spliceArrayFromName", "tesseramento_tipologie");
      getTesserati();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedtesserati_list")
    );
    const searchTesseratoList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTesserato");
      getTesserati();
    };

    getTesserati();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Tesserati Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
    });

    return {
      getTesserati,
      resetFilters,
      searchTesseratoList,
    };
  },
});
</script>
