<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_upload_batch"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <div class="modal-content" v-if="tipologia_batch">
        <div class="modal-header">
          <h5 class="modal-title">
            Caricamento Batch {{ tipologia_batch.label }}
          </h5>
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="file = {}"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card-body p-6">
            <div class="ps-5 pe-5">
              <div class="row pb-4">
                <div class="col-md-12">
                  <p>
                    Tramite questa funzionalità è possibile introdurre gruppi di
                    tesseramenti, per le tipologie di
                    {{ tipologia_batch.label }}, in modalità batch. Il file
                    dovrà essere in formato CSV (estensione .csv) Qualunque
                    errore di congruenza dei dati trasmessi annullerà
                    l'operazione.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-1">
                  <label class="fw-bold">File </label>
                </div>

                <div class="col-lg-11 d-flex ps-0">
                  <div
                    class="image-input image-input-outline me-5"
                    data-kt-image-input="true"
                    style="background-image: url(media/avatars/blank.png)"
                  >
                    <label
                      class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change file"
                    >
                      <i class="bi bi-upload fs-7"></i>
                      <!--begin::Inputs-->
                      <input
                        type="file"
                        name="avatar"
                        accept=".csv"
                        @change="onInputChange"
                      />
                      <!--end::Inputs-->
                    </label>
                  </div>
                  <input
                    type="text"
                    class="form-control me-2 ms-5"
                    placeholder=""
                    aria-label=""
                    :value="file ? file.id : ''"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="modalImporta"
            @click="file = {}"
          >
            Chiudi
          </span>
          <span
            type="button"
            class="badge bg-secondary rounded blueFit fs-6"
            @click="caricaBatch"
          >
            Carica Tessere
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, ref, computed, watch } from "vue";
import useFileList from "@/composables/file-list";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed } from "@/composables/swAlert";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "upload-tessere-batch",
  components: { Loading },
  emits: ["refreshList"],
  props: {
    tipologia_batch: {
      type: Object,
      required: true,
    },
    id_societa: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const { file, addFile, removeFile } = useFileList();
    function onInputChange(e) {
      addFile(e.target.files, ".csv");
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
    }

    const store = useStore();
    const router = useRouter();
    const stagione = computed(() => store.getters.stagioneSelected);

    watch(stagione.value);

    const isLoading = ref(false);

    const caricaBatch = () => {
      isLoading.value = true;
      const bodyFormData = ref(new FormData());
      bodyFormData.value.append("file", file.value.file);
      bodyFormData.value.append("id_societa", props.id_societa);
      bodyFormData.value.append("stagione", stagione.value);
      bodyFormData.value.append("id_tipologia", props.tipologia_batch.id);
      sendFiles(bodyFormData.value, globalApi.IMPORTAZIONI_BATCH).then(
        (res) => {
          isLoading.value = false;
          const { status, data } = res;
          if (status != 200) {
            file.value.status = false;
            alertFailed(
              data.message
                ? data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          } else {
            file.value.status = true;
            Swal.fire({
              html: "L'importazione verrà effettuata dal sistema.<br><br> E' possibile visualizzare lo stato e il dettaglio dell'importazione all'interno della pagina 'Importazioni'",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Lista Importazioni",
              cancelButtonText: "Chiudi",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn fw-bold btn-light-success",
                cancelButton: "btn fw-bold btn-light-danger",
              },
            }).then((input) => {
              document.getElementById("modalImporta").click();
              if (input.isConfirmed) {
                router.push("/importazioni");
                return;
              }
              file.value = {};
            });
          }
        }
      );
    };
    return {
      file,
      addFile,
      removeFile,
      onInputChange,
      caricaBatch,
      isLoading,
    };
  },
};
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
    margin-top: 5rem;
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
    margin-top: 4rem;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
